<template>
  <div class="subinvoicing">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>开票服务</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/invoicing" title="开票服务" class="on">开票服务</a>
        <a href="/baseHome/valueAdded" title="增值服务">增值服务</a>
        <a href="/baseHome/ourservice" title="我们的服务">我们的服务</a>
      </div>
    </div>
    <div class="sub-title">开票服务</div>
    <div class="wrapper">
      <div><img src="../../assets/images/invoicing.jpg"></div>
      <div class="invoicing">
        <p>易开票是山水物联网络货运平台针对物流企业和货主企业解决进项问题的一款特色服务，由于司机无法提供发票，很多公司为解决进项问题而发愁，我们能够实现运单、运输轨迹、资金、票据、证据五流合一，代司机开具合法合规的发票，无发票金额限制，解决企业缺进项的痛点，帮助还能给企业垫付运费，缓解资金周转问题，司机运费实时到账，提高司机运输积极性，加快物流运输步伐。</p>
        <p>司机无法提供发票，很多公司为解决进项问题而发愁，我们能够实现运单、运输轨迹、资金、票据、证据五流合一，代司机开具合法合规的发票，无发票金额限制，解决企业缺进项的痛点，帮助还能给企业垫付运费，缓解资金周转问题，司机运费实时到账，提高司机运输积极性，加快物流运输步伐。易开票是山水物联网络货运平台针对物流企业和货主企业解决进项问题的一款特色服务，由于司机无法提供发票，很多公司为解决进项问题而发愁，我们能够实现运单、运输轨迹、资金、票据、证据五流合一，代司机开具合法合规的发票，无发票金额限制，解决企业缺进项的痛点，帮助还能给企业垫付运费，缓解资金周转问题，司机运费实时到账，提高司机运输积极性，加快物流运输步伐。</p>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {

    }
  },

  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subinvoicing {
  .bg{background:url(./../../assets/images/serbg.jpg) }
  .invoicing{padding:50px 0;
    p{ text-indent: 2em; padding:20px 0;line-height: 39px; color: #222; font-size: 16px;}
  }
}
</style>
